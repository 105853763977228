<template>
  <v-app id="inspire">
    <Sidebar :drawer="$store.state.drawer"></Sidebar>
    <Header :drawer="$store.state.drawer"></Header>
     <v-content>
      <v-container fluid> <br><br>
     <span v-if="user_type">
       <center class="heading">Student Subject Details</center>
       <v-simple-table class="table-data" v-if="learner_course!=null">
            <template v-slot:default>
               <thead>
                  <tr>
                     <th class="text-left"><center>Sr.No</center></th>
                     <th class="text-left"><center>Course Code</center></th>
                     <th class="text-left"><center>Course Name</center></th>
                     <th class="text-left"><center>Exam</center></th>
                      <th class="text-left"><center>Exam Date</center></th>
                      <th class="text-left"><center>Exam Slot</center></th>
                  </tr>
               </thead>
               <tbody>
                  <tr v-for="(item,index) in learner_course" :key="item.id">
                     <td>{{ index + 1 }}</td>
                     <td>{{ item.course_code }}</td>
                     <td>{{ item.course_name }}</td>
                      <td>{{item.exam}}</td>
                      <td>{{item.exam_date}}</td>
                      <td>{{item.start_time}} to {{item.end_time}}</td>
                  </tr>
               </tbody>
            </template>
         </v-simple-table>
         <v-row class="justify-center">
           <v-btn class="button" @click="startExam">GIVE EXAM</v-btn>
         </v-row>
     </span>
      </v-container>
    </v-content>
      <Footer :drawer="$store.state.drawer"></Footer>
  </v-app>
</template>
<script>
import Sidebar from "../components/shared/Sidebar";
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import axios from "axios";
// import "@/assets/css/color.css";
 // css needs to be imported for each dashboard

export default {
  data: () => ({
    dialog: false,
    drawer: null,
    learner_course:"",
    user_type:"",
    
  }),
  components: {
    Sidebar: Sidebar,
    Header: Header,
    Footer: Footer,
  },
  mounted(){
this.onload()
  },
 methods:{
   startExam(){
    this.$router.push({ name: 'stud-start-exam', target:"blank"})
   },
   onload(){
     axios
          .post("/student/checkUserType")
                    .then((res) => {
                        if (res.data.status == "YES") {
                          this.learner_course = res.data.learner_course;
                          this.user_type = res.data.user_type;
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    })
            .finally(() => {});
   }
 }
};
</script>


<style scoped>
    .button{
    margin: 1rem;
    }
  .table-data {
        margin: 8px;
    }
    .table-data {
        margin: 8px;
    }
    v-simple-table {
        width: auto;
        border: 1px solid hsla(190, 99%, 30%, 1);
    }
    td,
    th {
        padding: 6px;
        border: 1px solid hsla(190, 99%, 30%, 1);
        text-align: center;
    }
    .text
    {
      color: white;
    }
</style>